
import Vue from 'vue';
import axios, { AxiosResponse } from 'axios';

export default Vue.extend({
  components: {},
  data: () => ({
    imageFile: '',
    jsonFile: '',
    statusConfig: '{\n}',
    loadedConfig: 'loading...',
    loadConfigInterval: null,
    imageErrorMessage: '',
    jsonFileErrorMessage: '',
    jsonErrorMessage: '',
    imageUploading: false,
    fileUploading: false,
    jsonUploading: false,
    jsonLoading: false,
    imageFiles: [],
    jsonFiles: [],
    s3Response: '',
  }),
  methods: {
    showErrorMessage(message, host): void {
      this[host] = message;
      setTimeout(() => {
        this[host] = '';
      }, 5000);
    },
    async loadConfig(): Promise<void> {
      this.jsonLoading = true;

      await this.$store
        .dispatch('status/getDashboardStatus')
        .then(data => {
          if (!data) {
            // eslint-disable-next-line no-console
            console.log('getDashboardStatus bad response', data);
            this.loadedConfig = 'error';
            return;
          }
          this.loadedConfig = JSON.stringify(data, null, 2);
          this.statusConfig = this.loadedConfig + '';
        })
        .catch((error: Error) => {
          this.loadedConfig = 'error';
          // eslint-disable-next-line no-console
          console.error('getDashboardStatus error', error);
          return;
        })
        .finally(() => {
          this.jsonLoading = false;
        });
    },
    sendJson(): void {
      if (!this.statusConfig || this.statusConfig.length < 1) {
        this.showErrorMessage('empty json', 'jsonErrorMessage');
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let parsed: any = null;
      try {
        parsed = JSON.parse(this.statusConfig);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('send json', err);
        this.showErrorMessage('invalid json', 'jsonErrorMessage');
        return;
      }
      this.jsonUploading = true;
      axios
        .post('/api/setDashboardStatus', parsed)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          if (resp && resp.data) {
            const { data } = resp;
            if (!data.auth) {
              this.showErrorMessage('not authorized', 'jsonErrorMessage');
              // eslint-disable-next-line no-console
              console.log('setDashboardStatus error 1', resp);
              return;
            }
            if (data.error) {
              this.showErrorMessage(data.error, 'jsonErrorMessage');
              // eslint-disable-next-line no-console
              console.log('setDashboardStatus error 2', resp);
              return;
            }
            if (!data.body) {
              this.showErrorMessage('no data', 'jsonErrorMessage');
              // eslint-disable-next-line no-console
              console.log('setDashboardStatus error 3', resp);
              return;
            }
            if (data.body.s3) {
              this.s3Response = JSON.stringify(data.body.s3, null, 2);
            } else {
              this.s3Response = 'failed to store on S3';
            }
            if (data.body.json) {
              this.statusConfig = JSON.stringify(data.body.json, null, 2);
            } else {
              this.s3Response = 'failed to update';
              // eslint-disable-next-line no-console
              console.log('setDashboardStatus error 6', this.data);
            }
          } else {
            this.showErrorMessage('no response from api', 'jsonErrorMessage');
            // eslint-disable-next-line no-console
            console.log('setDashboardStatus error 4', resp);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          // eslint-disable-next-line no-console
          console.log('setDashboardStatus error 5', err);
        })
        .finally(() => {
          this.jsonUploading = false;
        });
    },
    sendImageFile(): void {
      if (!this.file || this.file.length < 1) {
        this.showErrorMessage('no file selected', 'imageErrorMessage');
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      this.imageUploading = true;
      axios
        .post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          if (resp && resp.data) {
            const { data } = resp;
            if (Array.isArray(data)) {
              this.imageFiles = data;
              return;
            }
            if (data.error) {
              this.showErrorMessage(data.error, 'imageErrorMessage');
              // eslint-disable-next-line no-console
              console.log('sendImageFile error 1', resp);
              return;
            }
            this.showErrorMessage('unexpected response, check console', 'imageErrorMessage');
            // eslint-disable-next-line no-console
            console.log('unexpected upload response', resp);
          } else {
            this.showErrorMessage('no response from api', 'imageErrorMessage');
            // eslint-disable-next-line no-console
            console.log('sendImageFile error 2', resp);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          // eslint-disable-next-line no-console
          console.log('sendImageFile error 3', err);
        })
        .finally(() => {
          this.imageUploading = false;
        });
    },
    onFilePicked(): void {
      this.file = this.$refs.file.files[0];
    },
    onJSONFilePicked(): void {
      this.jsonFile = this.$refs.jsonFile.files[0];
    },
    // sendJSONFile(): void {
    //   if (!this.jsonFile || this.jsonFile.length < 1) {
    //     this.showErrorMessage('no file selected', 'jsonFileErrorMessage');
    //     return;
    //   }
    //   const formData = new FormData();
    //   formData.append('file', this.jsonFile);
    //   this.fileUploading = true;
    //   axios
    //     .post('/api/updateStatusFile', formData, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //       },
    //     })
    //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     .then((resp: AxiosResponse<any>) => {
    //       if (resp && resp.data) {
    //         const { data } = resp;
    //         if (data.filename) {
    //           this.jsonFiles = [data];
    //           return;
    //         }
    //         if (data.error) {
    //           this.showErrorMessage(data.error, 'jsonFileErrorMessage');
    //           // eslint-disable-next-line no-console
    //           console.log('sendJSONFile error 1', resp);
    //           return;
    //         }
    //         this.showErrorMessage('unexpected response, check console', 'jsonFileErrorMessage');
    //         // eslint-disable-next-line no-console
    //         console.log('unexpected sendJSONFile response', resp);
    //       } else {
    //         this.showErrorMessage('no response from api', 'jsonFileErrorMessage');
    //         // eslint-disable-next-line no-console
    //         console.log('sendJSONFile error 2', resp);
    //       }
    //     })
    //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     .catch((err: any) => {
    //       // eslint-disable-next-line no-console
    //       console.log('sendJSONFile error 3', err);
    //     })
    //     .finally(() => {
    //       this.fileUploading = false;
    //     });
    // },
  },
  mounted() {
    this.loadConfig(true);
    // this.loadConfigInterval = setInterval(() => {
    //   this.loadConfig();
    // }, 1000);
  },
  beforeDestroy(): void {
    clearInterval(this.loadConfigInterval);
  },
});
